import * as React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { BackgroundLoad } from '../../layout/BackgroundLoad';
import { Thanks } from '../../layout/Thanks';

import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';

import { AccordionGeneral } from '../../partials/AccordionGeneral';
import { AccordionBenefits } from '../../partials/AccordionBenefits';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { Seo } from '../../../utils/seo/Index';

import { AboutValuationText } from '../../partials/AboutValuationText';
import { useValuationContext } from '../../../utils/hooks/useValuationContext';
import { PageType } from '../../../__types__/generated/globalTypes';
import { ValuationForm } from '../../partials/forms/Valuation';

import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { ValuationContextProvider } from '../../../utils/context/ValuationContext';

const Index: React.FC = () => {
  const location = useLocation();
  const { posted, init } = useValuationContext();

  React.useEffect(() => {
    init({
      pageType: PageType.GENERELL
    });
  }, []);

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: true
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  return (
    <>
      <Seo
        title="Vi hjelper deg gjerne! - PrivatMegleren"
        description="Som kunde hos PrivatMegleren får du en skreddersydd prosess tilpasset dine behov og ønsker."
        url={location.pathname}
      />

      <Container className="padding-top" style={pageFadeIn}>
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h1" center={true}>
              Vi hjelper deg gjerne!
            </Heading>
            <Paragraph center={true} className="form-text">
              Det å kjøpe eller selge eiendom er ikke noe man gjør ofte. Derfor
              er det heller ikke overraskende om usikkerheten kommer snikende.
              Er denne eiendommen verdt det selger vil ha? Bør vi legge nytt
              gulv i stuen før vi skal selge?
            </Paragraph>
            <Paragraph center={true} className="form-text">
              Våre meglere bistår deg gjerne på din reise i eiendomsmarkedet.
              Være seg rådgivning ved kjøp eller verdivurdering i forbindelse
              med salg. Start med en helt uforpliktende samtale, fyll ut skjema
              under.
            </Paragraph>
          </Section>
          <ValuationForm />
          <Section className="form-section">
            <AboutValuationText />
          </Section>
          <Section className="form-section">
            <AccordionBenefits />
          </Section>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
            <Paragraph center={true}>
              I mellomtiden kan du lese mer om salg av eiendom her.
            </Paragraph>
            <AccordionGeneral />
          </Section>
          <Section className="form-section" style={{ paddingTop: 0 }}>
            <AccordionBenefits />
          </Section>
        </Thanks>
      </Container>
      <BackgroundLoad
        opacity={0.3}
        url="https://cdn.reeltime.no/pm_assets/img/cbimage.jpg"
      />
    </>
  );
};

const Dagensboligmarked: React.FC = (props) => {
  return (
    <ValuationContextProvider>
      <Index {...props} />
    </ValuationContextProvider>
  );
};

export default Dagensboligmarked;

const FormWrapper = styled(animated.div)``;
