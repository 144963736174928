import * as React from 'react';

import { useValuationContext } from '../../../utils/hooks/useValuationContext';
import { Button } from '../../ui/button/Button';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Consent } from '../../ui/form/Consent';
import { Form, FormFieldsWrapper, FormRow } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { ButtonLink } from '../../ui/link/ButtonLink';

export const ValuationForm: React.FC = React.memo(() => {
  const { error, loading, fields, consents, onChange, onSubmit } =
    useValuationContext();

  return (
    <Form onSubmit={onSubmit} noValidate>
      {error?.graphQLErrors?.map((error) => (
        <FormError message={error.message} />
      ))}
      <FormFieldsWrapper>
        {fields
          .filter((item) => item?.type !== 'checkbox')
          .map((item, index) => {
            return (
              <FormRow key={`field_${index}`}>
                <Input
                  type={item?.type}
                  name={item?.name}
                  label={item?.label}
                  labelAfter={item?.labelAfter}
                  placeholder={item?.placeholder}
                  value={item?.value}
                  onChange={onChange}
                  error={item?.error}
                />
              </FormRow>
            );
          })}
      </FormFieldsWrapper>
      <Consent fields={consents} handleChange={onChange} />
      <ButtonGroup>
        <Button
          type="primary"
          colorTheme="gold"
          disabled={loading}
          loading={loading}
        >
          Kontakt meg
        </Button>
        <ButtonLink as="link" to="/personvern" className="privacy-link-mobile">
          Personvernpolicy
        </ButtonLink>
      </ButtonGroup>
    </Form>
  );
});
