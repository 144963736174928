import * as React from 'react';
import styled from 'styled-components';
import { Checkbox } from './Checkbox';
import { FormRow } from './Form';

const ConsentContainer = styled.div``;
export const ConsentHeading = styled.div`
  color: #fff;
  margin: 0 0 1em;
  font-size: 16px;
`;

export const ConsentWrapper = styled.div<{ withBackground?: boolean }>`
  margin: 1em 0 0;
  padding: ${({ withBackground }) => (withBackground ? '1em' : '1em 0')};
  clear: both;
  overflow: auto;
  background-color: ${({ withBackground }) =>
    withBackground ? 'rgb(24, 24, 25)' : 'transparent'};
`;

export const Consent: React.FC<{
  fields: any[];
  heading?: string;
  withBackground?: boolean;
  handleChange: any;
  className?: string;
}> = ({ fields, heading, handleChange, withBackground, className }) => {
  return (
    <ConsentContainer className={className}>
      <ConsentHeading className="consent-title">
        {heading
          ? heading
          : 'Jeg anmoder PrivatMegleren om å kontakte meg på e-post og /eller telefon i form av sms eller oppringing.'}
      </ConsentHeading>
      <ConsentWrapper className="consent-list" withBackground={withBackground}>
        {fields
          .filter((item) => item?.type === 'checkbox')
          .map((item: any, index: number) => {
            return (
              <FormRow className="consent-item" key={`field_consent_${index}`}>
                <Checkbox
                  {...item}
                  onChange={handleChange}
                  error={item.error}
                  labelPos="right"
                />
              </FormRow>
            );
          })}
      </ConsentWrapper>
    </ConsentContainer>
  );
};
